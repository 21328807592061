import styled from 'styled-components';

export interface ButtonProps {
  bg?: string | null;
  textColor?: string | null;
}

export const Button = styled.button<ButtonProps>`
  background-color: ${props => props.theme.colors.color1};
  color: ${props => props.theme.colors.color2};
  border: none;
  padding: 0.75rem 2rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.35em;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s;
  background-image: none;
  box-shadow: 0 0 0 4px transparent;
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${props => props.theme.colors.color2};
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.3);
  }
`;
