import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  fontSizes: [12, 16, 18, 22, 25, 32, 45, 65],
  space: [0, 4, 8, 16, 32, 64, 128, 160, 192, 224, 256, 288, 320, 512],
  breakpoints: ['40em', '52em', '64em', '90em', '120em'],
  colors: {
    primary: '#18415C',
    text: '#333',
    background: '#fff',
    grey: '#9B9B9B',
    lightGrey: '#d8dbe1',
    accent: '#C9BBA3',
    color1: 'hsl(45.5, 73.4%, 69%)',
    color2: '#000',
  },
};
