import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { Box } from '../../designsystem/Box';
import { ImageSlice } from '../../__generated__/graphql';

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
`;

const Heading = styled.h1`
  font-size: 4rem;
`;

export const ImageBlock: React.FC<{ block: ImageSlice }> = ({ block }) => {
  const data = block.primary!;
  const fluid = data.image!.localFile!.childImageSharp!.fluid as any;

  return (
    <Box
      position="relative"
      mb={4}
      px={4}
      mx="auto"
      maxWidth={data.width === 'ContentWidth' ? '800px' : 'initial'}
    >
      <Img fluid={fluid} />
    </Box>
  );
};
