import { graphql } from 'gatsby';
import React from 'react';
import { BlockArea } from '../components/blocks/BlockArea';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { isNotNull } from '../utils/isNotNull';
import { PrismicPageQuery } from '../__generated__/graphql';

const PrismicPage: React.FC<{ data: PrismicPageQuery }> = ({ data }) => {
  const page = data.prismicPage!.data!;
  const blocks = (page.body || []).filter(isNotNull);

  return (
    <Layout>
      <SEO title={page.name!} description={page.description!} />
      <BlockArea blocks={blocks} />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query PrismicPageQuery($id: String) {
    prismicPage(prismicId: { eq: $id }) {
      prismicId
      data {
        name
        description
        slug
        body {
          __typename
          ... on Node {
            id
          }
          ...BodyTextSlice
          ...JumbotronSlice
          ...ContactPeopleSlice
          ...LogoSlice
        }
      }
    }
  }

  fragment BodyTextSlice on PrismicPageBodyText {
    primary {
      text {
        html
      }
    }
  }

  fragment JumbotronSlice on PrismicPageBodyJumbotron {
    primary {
      title
      image {
        localFile {
          childImageSharp {
            fixed(width: 1920, height: 500) {
              aspectRatio
              base64
              height
              width
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
      text {
        html
      }
      sub_title
    }
  }

  fragment ContactPeopleSlice on PrismicPageBodyContactPeople {
    items {
      staffpage {
        document {
          data {
            name
            slug
            quote
            e_mail
            text {
              html
            }
          }
        }
      }
    }
  }

  fragment LogoSlice on PrismicPageBodyLogo {
    id
  }
`;

export default PrismicPage;
