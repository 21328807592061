import React from 'react';
import { Box } from '../../designsystem/Box';
import { Button } from '../../designsystem/Button';
import { ButtonSlice } from '../../__generated__/graphql';

const LinkButton = Button.withComponent('a');

export const ButtonBlock: React.FC<{ block: ButtonSlice }> = ({ block }) => {
  return (
    <Box
      px={4}
      mb={4}
      mx="auto"
      maxWidth={800}
      display="flex"
      justifyContent="center"
    >
      <LinkButton href={block.button!.link!}>{block.button!.text}</LinkButton>
    </Box>
  );
};
