import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { Box } from '../../designsystem/Box';
import { JumbotronSlice } from '../../__generated__/graphql';

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
`;

const Heading = styled.h1`
  font-size: 4rem;
`;

export const JumbotronBlock: React.FC<{ block: JumbotronSlice }> = ({
  block,
}) => {
  const file = block.primary!.image!.localFile;
  return (
    <Box position="relative" mb={4}>
      {file && <Img fixed={file.childImageSharp!.fixed as any} />}
      <Content>
        <Heading>{block.primary!.title}</Heading>
        <p>{block.primary!.sub_title}</p>
      </Content>
    </Box>
  );
};
