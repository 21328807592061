import React from 'react';
import { Box } from '../../designsystem/Box';
import { ReactComponent as Logo } from '../../images/hoome-united-logo.svg';

export const LogoBlock: React.FC<{ block: any }> = () => {
  return (
    <Box maxWidth={300} m="0 auto" pt={3}>
      <Logo />
    </Box>
  );
};
