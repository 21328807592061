import React from 'react';
import {
  PrismicPageQuery_prismicPage_data_body,
  UnitedPageQuery_prismicUnitedsettings_data_body,
} from '../../__generated__/graphql';
import { BodyTextBlock } from './BodyText';
import { ButtonBlock } from './ButtonBlock';
import { ContactPeopleBlock } from './ContactPeople';
import { ImageBlock } from './ImageBlock';
import { JumbotronBlock } from './Jumbotron';
import { LogoBlock } from './LogoBlock';

type BlockTypes =
  | UnitedPageQuery_prismicUnitedsettings_data_body
  | PrismicPageQuery_prismicPage_data_body;

const BLOCK_TYPES: {
  [key in BlockTypes['__typename']]: React.FunctionComponent<{
    block: any;
  }>
} = {
  PrismicPageBodyText: BodyTextBlock,
  PrismicPageBodyJumbotron: JumbotronBlock,
  PrismicPageBodyContactPeople: ContactPeopleBlock,
  PrismicPageBodyLogo: LogoBlock,
  PrismicUnitedsettingsBodyText: BodyTextBlock,
  PrismicUnitedsettingsBodyImage: ImageBlock,
  PrismicUnitedsettingsBodyButton: ButtonBlock,
};

export const BlockArea: React.FC<{
  blocks: BlockTypes[];
}> = ({ blocks }) => {
  return (
    <>
      {blocks.map(block => {
        const Component = BLOCK_TYPES[block.__typename];

        if (!Component) return <pre>NOT FOUND: {block.__typename}</pre>;

        return <Component key={block.id} block={block} />;
      })}
    </>
  );
};
