import styled from 'styled-components';
import {
  alignItems,
  AlignItemsProps,
  gridArea,
  GridAreaProps,
  gridAutoColumns,
  GridAutoColumnsProps,
  gridAutoFlow,
  GridAutoFlowProps,
  gridAutoRows,
  GridAutoRowsProps,
  gridColumn,
  gridColumnGap,
  GridColumnGapProps,
  GridColumnProps,
  gridGap,
  GridGapProps,
  gridRow,
  gridRowGap,
  GridRowGapProps,
  GridRowProps,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  GridTemplatesAreasProps,
  GridTemplatesColumnsProps,
  GridTemplatesRowsProps,
  justifyContent,
  JustifyContentProps,
} from 'styled-system';

export type GridProps = AlignItemsProps &
  JustifyContentProps &
  GridAreaProps &
  GridAutoColumnsProps &
  GridAutoFlowProps &
  GridAutoRowsProps &
  GridColumnGapProps &
  GridColumnProps &
  GridGapProps &
  GridRowGapProps &
  GridRowProps &
  GridTemplatesAreasProps &
  GridTemplatesColumnsProps &
  GridTemplatesRowsProps;

export const Grid = styled.div<GridProps>`
  display: grid;
  ${alignItems};
  ${justifyContent};
  ${gridArea};
  ${gridAutoColumns};
  ${gridAutoFlow};
  ${gridAutoRows};
  ${gridColumn};
  ${gridColumnGap};
  ${gridGap};
  ${gridRow};
  ${gridRowGap};
  ${gridTemplateAreas};
  ${gridTemplateColumns};
  ${gridTemplateRows};
`;
