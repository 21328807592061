import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../theme';
import './layout.css';

const Layout: React.FC = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
          <div>{children}</div>
          {/* <Footer /> */}
        </>
      )}
    />
  </ThemeProvider>
);

export default Layout;
