import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Box } from '../designsystem/Box';
import { Grid } from '../designsystem/Grid';
import { isNotNull } from '../utils/isNotNull';
import { FooterQuery } from '../__generated__/graphql';

const FooterTitle = styled.p`
  color: ${props => props.theme.colors.primary};
  font-weight: 600;
  margin-top: 0;
`;

const FooterLinks = styled.div`
  padding: 0;
  margin: 1.5rem 0 0 0;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.58;
  letter-spacing: -0.003em;
`;
const FooterBox = styled(Box)`
  /* border-top: 1px solid rgba(0, 0, 0, 0.2); */
`;

const Footer: React.FC = () => {
  const data = useStaticQuery<FooterQuery>(query);

  const settings = data.prismicUnitedsettings!.data!;

  return (
    <Box px={4} bg="#f3f3f3">
      <FooterBox py={4} px={4} maxWidth={1600} mx="auto">
        <Grid gridTemplateColumns={['1fr', '1fr', '2fr 1fr 1fr']} gridGap={4}>
          <Box>
            <FooterTitle>Välkommen hem</FooterTitle>
            <p>
              Hos Hoome Mäkleri hittar du inte bostadsrätter, villor eller
              radhus. Hos oss hittar du bara hem. Hem för stora och små, för
              gammal och ung. Hem för återhämtning och umgänge. Hem där du kan
              växa och skapa dina egna minnen. Där du trivs. Där är ditt hem.
            </p>
          </Box>
          <Box>
            <FooterTitle>En kopp kaffe?</FooterTitle>
            <div
              dangerouslySetInnerHTML={{ __html: settings.address!.html! }}
            />
            <p>
              <div>
                <a href={`mailto:${settings.email}`}>{settings.email}</a>
              </div>
              <div>
                <a href={`tel:${settings.phone}`}>{settings.phone}</a>
              </div>
            </p>
          </Box>
          <Box>
            <FooterTitle>&hellip; och en kaka?</FooterTitle>
            <FooterLinks>
              {settings.links!.filter(isNotNull).map(link => (
                <div key={link.url!}>
                  <Link to={link.url!}>{link.label}</Link>
                </div>
              ))}
            </FooterLinks>
          </Box>
        </Grid>
      </FooterBox>
    </Box>
  );
};

const query = graphql`
  query FooterQuery {
    prismicUnitedsettings {
      data {
        address {
          html
        }
        email
        phone
        links {
          label
          url
        }
      }
    }
  }
`;

export default Footer;
