import React from 'react';
import { Box } from '../../designsystem/Box';
import { BodyTextSlice } from '../../__generated__/graphql';

export const BodyTextBlock: React.FC<{ block: BodyTextSlice }> = ({
  block,
}) => {
  return (
    <Box
      px={4}
      mb={4}
      mx="auto"
      maxWidth={800}
      dangerouslySetInnerHTML={{ __html: block.primary!.text!.html! }}
    />
  );
};
