import styled from 'styled-components';
import { alignItems, AlignItemsProps, bottom, BottomProps, color, ColorProps, display, DisplayProps, flex, flexDirection, FlexDirectionProps, FlexProps, flexWrap, FlexWrapProps, height, HeightProps, justifyContent, JustifyContentProps, left, LeftProps, maxWidth, MaxWidthProps, minWidth, MinWidthProps, position, PositionProps, right, RightProps, size, SizeProps, space, SpaceProps, top, TopProps, width, WidthProps } from 'styled-system';

export type BoxProps = AlignItemsProps &
  ColorProps &
  BottomProps &
  DisplayProps &
  FlexProps &
  FlexDirectionProps &
  FlexWrapProps &
  HeightProps &
  JustifyContentProps &
  LeftProps &
  PositionProps &
  RightProps &
  SizeProps &
  SpaceProps &
  TopProps &
  WidthProps &
  MaxWidthProps &
  MinWidthProps;

export const Box = styled.div<BoxProps>`
  ${color}
  ${display}
  ${flex}
  ${flexDirection}
  ${flexWrap}
  ${height}
  ${size}
  ${space}
  ${width}
  ${position}
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${alignItems}
  ${justifyContent}
  ${maxWidth}
  ${minWidth}
`;
